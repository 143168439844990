@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  margin: 0;
}

.bg-login {
  min-height: 100vh; /* Ensures the background container covers at least the viewport height */
  width: 100vw;
  background-image: url("./bg.jpg");
  background-size: auto; /* Keeps the original image size */
  background-position: center;
  background-repeat: repeat-y;
  background-attachment: fixed; /* Ensures the background stays fixed while scrolling */
}
