@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-login {
  background-image: url("../bg.webp");
  background-size: cover;
  background-position: center;
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.min-h-screen {
  min-height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
}

/* Dynamically calculate the viewport height for mobile */
:root {
  --vh: 100%; /* Set default value */
}

.flex-center {
  display: flex;
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  height: calc(var(--vh, 1vh) * 100); /* Use dynamic viewport height */
}

/* Other relevant styles */
.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.relative {
  position: relative;
}

.object-cover {
  object-fit: cover;
}

.bg-black {
  background-color: black;
}

.opacity-50 {
  opacity: 0.5;
}

.bg-with-opacity {
  background: rgba(255, 255, 255, 0.8); /* For translucent background */
}

.input-no-opacity {
  background: rgba(255, 255, 255, 0.9); /* Ensure inputs are not transparent */
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.input-no-opacity {
  opacity: 1 !important; /* Ensure the inputs are fully opaque */
}
.bg-with-opacity {
  background-color: rgba(255, 255, 255, 0.4); /* Semi-transparent background */
}
.fader {
  height: 300px;
  position: relative;
  overflow: hidden;
}

.fader__slide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.fader img {
  background-color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.navigation-wrapper {
  position: relative;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #000;
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #fff;
  cursor: pointer;
}

.arrow--left {
  left: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
}

.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}
